import { ContentWrapper } from "@/layout/marketControl";
import { useLayoutEffect } from "react";
import { useRouter } from "next/router";
import { queryClient } from "@/provider";

import withAuth from "@/layout/partials/withAuth";

function Dashboard() {
  const { asPath, replace } = useRouter();

  useLayoutEffect(() => {
    queryClient.invalidateQueries(["ws"]);
    if (asPath === "/dashboard")
      replace({
        query: {
          board: "All",
          orders: "All",
          security: "SMAZ",
          view: "Trading",
          time: "1D",
        },
      });
  }, []);

  return <ContentWrapper />;
}

export default withAuth(Dashboard, { withSidebar: false });
